import React from "react";
import "antd/dist/antd.css";
import { Button, Modal, Form, Input, message } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";

export default function ErrorReport(props) {
  const { errorState, setErrorState, errorConfirm, errorResolve, isError } =
    props;
  const color = { color: "red" };
  const handleInputChange = (evt) => {
    setErrorState({ ...errorState, input: evt.target.value });
  };

  const handleInputKeyDown = (evt) => {
    if (evt.keyCode === 13 || evt.keyCode === 9 || evt.keyCode === 32) {
      const { value } = evt.target;
      if (errorState.tags.length < 3) {
        setErrorState((state) => ({
          ...state,
          tags: [...state.tags, value],
          input: "",
        }));

        if (
          errorState.tags &&
          errorState.tags.length &&
          evt.keyCode === 8 &&
          !errorState.input.length
        ) {
          setErrorState((state) => ({
            state,
            tags: state.tags.slice(0, state.tags.length - 1),
          }));
        }
      } else {
        message.error("can't enter more then 3 tags");
      }
    }
  };

  const handleRemoveItem = (index) => {
    return () => {
      setErrorState((state) => ({
        state,
        tags: state.tags.filter((item, i) => i !== index),
      }));
    };
  };

  return (
    <Modal
      title="Error Report"
      visible={props.isErrorModal}
      footer={null}
      onCancel={props.handleCancel}
    >
      <Form method="post" layout="vertical" /*onFinish={onFinish}*/>
        <label>
          <span style={color}>* </span>課題の有無
        </label>
        <Input
          style={{ width: "33%" }}
          type="checkbox"
          checked={errorState.is_error}
          value={errorState.is_error}
          onChange={(e) => {
            setErrorState({ ...errorState, is_error: e.target.checked });
          }}
        />
        <br /> <br />
        <label>
          <span style={color}>* </span>課題に感じている点は何ですか？
        </label>
        <br />
        <Input
          value={errorState.issue}
          onChange={(e) => {
            setErrorState({ ...errorState, issue: e.target.value });
          }}
        />
        <br /> <br />
        <label>
          <span style={color}>* </span> それはなぜですか？
        </label>
        <br />
        <Input
          value={errorState.reason}
          onChange={(e) => {
            setErrorState({ ...errorState, reason: e.target.value });
          }}
        />
        <br /> <br />
        <label>
          <span style={color}>* </span>どう改善したいですか？
        </label>
        <br />
        <Input
          value={errorState.how}
          onChange={(e) => {
            setErrorState({ ...errorState, how: e.target.value });
          }}
        />
        <br /> <br />
        <label>
          <span style={color}>* </span>導入候補の機能
        </label>
        <br />
        <Input
          value={errorState.method}
          onChange={(e) => {
            setErrorState({ ...errorState, method: e.target.value });
          }}
        />
        <br /> <br />
        <label>
          <span style={color}>* </span>
          課題や解決の鍵となる主要なキーワードを３つ入力、または選んでください
        </label>{" "}
        <br />
        <label style={{ width: "100%" }}>
          <ul className="tag-container">
            {errorState.tags &&
              errorState.tags.map((item, i) => (
                <li key={i} className="items" onClick={handleRemoveItem(i)}>
                  {item}{" "}
                  <span style={{ marginTop: "-10px" }}>
                    <CloseCircleOutlined />
                  </span>
                </li>
              ))}
            <input
              className="tag-input"
              value={errorState.input}
              onChange={handleInputChange}
              onKeyDown={handleInputKeyDown}
            />
          </ul>
        </label>
        <span>
          <b>Note:</b> For making a tag press Tab, Enter or space{" "}
        </span>
        <br /> <br />
      </Form>
      <Button onClick={errorConfirm}>Save</Button>
      {isError && (
        <Button
          className="btn-primary"
          style={{ marginLeft: "19px" }}
          onClick={errorResolve}
        >
          Resolve
        </Button>
      )}
    </Modal>
  );
}
