import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "antd/dist/antd.css";
import "./style.css";
import {
  Card,
  Avatar,
  Button,
  Modal,
  message,
  Input,
  Divider,
  Popconfirm,
} from "antd";
import { EditOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import ReactHtmlParser from "react-html-parser";
import { AddProject } from "../../../app/pages/Projects/AddProject";
import { EditProject } from "../../../app/pages/Projects/EditProject";
import {
  listProject,
  sfsdfdsfsfs,
  retrieveProject,
  updateProjectAPI,
  deleteProjectAPI,
} from "../../../app/network/api";

export function Demo1Dashboard(props) {
  let history = useHistory();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditModal, setIsEditModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [ID, setID] = useState("");
  const [filters, setFilter] = useState("");
  const [data, setData] = useState([]);
  const [state, setState] = useState({
    name: "",
    description: "",
    enduser: parseInt(localStorage.getItem("enduser")),
  });
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setIsEditModal(false);
    setState({
      pname: "",
      name: "",
      description: "",
      enduser: parseInt(localStorage.getItem("enduser")),
    });
  };

  const viewProject = (id) => {
    history.push("/project/" + id);
  };

  const getProject = (id) => {
    retrieveProject(id).then((res) => {
      if (res.status === 200) {
        setState({
          enduser: res.data.enduser,
          name: res.data.name,
          description: res.data.description,
        });
        setID(id);
        setIsEditModal(true);
      }
    });
  };

  const deleteProject = (id) => {
    deleteProjectAPI(id).then((res) => {
      if (res.status === 204) {
        message.success("プロジェクトが正常に削除されました");
        listingAllProjects();
      }
    });
  };
  const addProject = (e) => {
    e.preventDefault();
    if (!state.name) {
      message.error("プロジェクト名を入力");
    } else {
      sfsdfdsfsfs(state).then((res) => {
        if (res && res.status === 201) {
          setIsModalVisible(false);
          listingAllProjects();
          setState({
            pname: "",
            name: "",
            description: "",
            enduser: parseInt(localStorage.getItem("enduser")),
          });
        }
      });
    }
  };

  const updateProject = (e) => {
    e.preventDefault();
    if (!state.name) {
      message.error("プロジェクト名を入力");
    } else {
      updateProjectAPI(state, ID).then((res) => {
        if (res && res.status === 200) {
          handleCancel();
          listingAllProjects();
          setID("");
        }
      });
    }
  };

  const { Meta } = Card;

  const [projectList, setProjectList] = useState([]);
  const listingAllProjects = () => {
    setLoading(true);
    listProject({ enduser: parseInt(localStorage.getItem("enduser")) }).then(
      (res) => {
        if (res && res.status === 200) {
          setProjectList(res.data);
          setData(res.data);
          setLoading(false);
        }
      }
    );
  };

  useEffect(() => {
    listingAllProjects();
  }, []);

  useEffect(() => {
    if (filters) {
      setProjectList(
        data.filter(
          (a) =>
            a.name.toUpperCase().includes(filters.toUpperCase()) ||
            a.description.toUpperCase().includes(filters.toUpperCase())
        )
      );
    } else setProjectList(data);
  }, [filters]);

  const confirm = (id) => {
    deleteProject(id);
  };
  return (
    <>
      <AddProject
        state={state}
        setState={setState}
        addProject={addProject}
        handleOk={handleOk}
        isModalVisible={isModalVisible}
        handleCancel={handleCancel}
      />
      <EditProject
        state={state}
        setState={setState}
        updateProject={updateProject}
        handleOk={handleOk}
        isEditModal={isEditModal}
        handleCancel={handleCancel}
      />
      <Input
        placeholder="プロジェクトを検索"
        onChange={(e) => {
          setFilter(e.target.value);
        }}
        style={{
          width: "50%",
          padding: "10px",
          margin: "0 auto",
          display: "block",
        }}
      />
      <div className="row" style={{ marginTop: "30px" }}>
        <div className="col-lg-12">
          <div className="dis-adj">
            <Button
              className="btn-adj-1"
              style={{ borderRadius: "48px" }}
              onClick={showModal}
            >
              <PlusOutlined style={{ color: "primary", fontSize: "18px" }} />{" "}
              新しいプロジェクトを作成
            </Button>
            <Divider
              className="divivder-adj"
              style={{ marginTop: "-10px", paddingRight: "93px" }}
              orientation="center"
            >
              マイプロジェクト
            </Divider>
          </div>

          <div className="row">
            {projectList && projectList.length > 0 ? (
              projectList
                .sort((a, b) => b.id - a.id)
                .map((d) => (
                  // <Card className="project" title="Project Name" bordered={true} style={{ width: 350 }}>

                  // </Card>
                  <Card
                    className="col-md-3"
                    style={{ border: "10px solid #f6f2ef", margin: "5px 0" }}
                    actions={[
                      <EditOutlined
                        key="edit"
                        onClick={() => getProject(d.id)}
                      />,
                      <Popconfirm
                        title="本当にこのプロジェクトを削除しますか？"
                        onConfirm={() => confirm(d.id)}
                        // onCancel={cancel}
                        okText="はい"
                        cancelText="いいえ"
                      >
                        <DeleteOutlined
                          key="ellipsis"
                          // onClick={() => deleteProject(d.id)}
                        />
                      </Popconfirm>,
                    ]}
                  >
                    <Meta
                      // avatar={
                      //   <Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
                      // }
                      style={{ cursor: "pointer" }}
                      onClick={() => viewProject(d.id)}
                      title={d.name}
                      description={ReactHtmlParser(d.description)}
                    />
                  </Card>
                ))
            ) : (
              <p style={{ margin: "0 auto", marginTop: "6rem" }}>
                {loading ? (
                  <span className="ml-3 spinner spinner-lg spinner-dark"></span>
                ) : (
                  "プロジェクトが見つかりません"
                )}
              </p>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Demo1Dashboard;
