import axios from "axios";
import { REACT_APP_API } from "../../config";
import { message } from "antd";
import { remove } from "lodash";
// const refreshToken = async (requireApiKey) => {
//   if (requireApiKey) {
//     const formData = new FormData();
//     formData.append("refresh", localStorage.getItem("token"));
//     return axios.post(`${REACT_APP_API}v1/token/refresh/`, formData);
//   } else return await axios.post(`${REACT_APP_API}v1/token/refresh/`);
// };
export const request = async ({ url, body = {}, query, type }) => {
  return axios({
    url: REACT_APP_API + url,
    data: body,
    params: { ...query },
    method: type,

    // ...(authToken && {
    //   headers: {
    //     Authorization: "Bearer " + authToken,
    //   },
    // }),
  }).catch((err) => {
    // new Error(err)
    if (err.response && err.response.status === 401) {
      localStorage.clear();
      window.location.href = "/auth/login";
    }
    if (err.response && err.response.status === 406) {
      for (let i = 0; i < Object.values(err.response.data.error).length; i++) {
        message.error(Object.values(err.response.data.error)[i]);
      }
    }
    if (
      err.response &&
      err.response.data &&
      err.response.data.message &&
      err.response.status !== 406
    ) {
      let errArray = err.response.data.message.split("'");
      if (errArray && errArray.length > 1) {
        message.error(errArray[1] + " " + errArray[3]);
      } else {
        message.error(err.response.data.message);
      }
    }
  });
};

export const registeration = (data) =>
  request({ url: "enduser/create/", body: data, type: "POST" });
export const login = (data) =>
  request({ url: "enduser/login/", body: data, type: "POST" });

export const sfsdfdsfsfs = (data) =>
  request({ url: "project/create/", body: data, type: "POST" });

export const listProject = (data) =>
  request({ url: "project/list/", query: data, type: "GET" });

export const retrieveProject = (data) =>
  request({ url: `project/retrieve/${data}/`, type: "GET" });

export const updateProjectAPI = (data, id) =>
  request({ url: `project/update/${id}/`, body: data, type: "PATCH" });

export const deleteProjectAPI = (id) =>
  request({
    url: `project/delete/${id}/`,
    type: "DELETE",
  });
export const getAllUsers = (data) =>
  request({ url: `enduser/list/`, type: "GET" });

export const createTaskAPI = (data) =>
  request({ url: "project/notes/create/", body: data, type: "POST" });

export const listTasksAPI = (data) =>
  request({ url: "project/notes/list/", query: data, type: "GET" });

export const retrieveTaskAPI = (data) =>
  request({ url: `project/notes/retrieve/${data}/`, type: "GET" });

export const updateTaskAPI = (id, data) =>
  request({ url: `project/notes/update/${id}/`, body: data, type: "PATCH" });

export const deleteTaskAPI = (data) =>
  request({ url: `project/notes/delete/${data}/`, type: "DELETE" });

export const socialAuthRegisteration = (data) =>
  request({ url: `enduser/googleauth/`, body: data, type: "POST" });

export const personCreate = (data) =>
  request({ url: `project/person/create/`, body: data, type: "POST" });

export const personList = (data) =>
  request({ url: `project/person/list/`, query: data, type: "GET" });

export const tagsList = (data) =>
  request({ url: `project/tag/list/`, type: "GET" });

export const createTagAPI = (data) =>
  request({ url: "project/tag/create/", body: data, type: "POST" });
