import React from "react";
import "antd/dist/antd.css";
import ReactQuill from "react-quill";
import { Button, Modal, Form, Input } from "antd";
import "react-quill/dist/quill.snow.css";

export function AddProject(props) {
  const { state, setState, addProject } = props;
  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "code"],
      ["clean"],
    ],
  };
  const color = { color: "red" };

  return (
    <Modal
      title="新しいプロジェクトを作成"
      visible={props.isModalVisible}
      onOk={props.handleOk}
      footer={null}
      onCancel={props.handleCancel}
    >
      <Form method="post" layout="vertical" /*onFinish={onFinish}*/>
        <label>
          <span style={color}>* </span>プロジェクト名
        </label>
        <br />
        <Input
          value={state.pname}
          placeholder="プロジェクト名"
          onChange={(e) => {
            setState({ ...state, name: e.target.value });
          }}
        />
        <br /> <br />
        <Form.Item label="詳細">
          <ReactQuill
            className="question-form__rich-text"
            theme="snow"
            bounds={".app"}
            style={{ height: "120px" }}
            value={state.description}
            modules={modules}
            onChange={(e) => {
              setState({ ...state, description: e });
            }}
          />
        </Form.Item>
      </Form>

      <Button style={{ marginTop: "60px" }} onClick={addProject}>
        保存
      </Button>
    </Modal>
  );
}
