import React, { useEffect, useState } from "react";
// Bootstrap for react
import { Container } from "react-bootstrap";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  LeftCircleOutlined,
} from "@ant-design/icons";
import { Card, Popconfirm, Badge, message, Button } from "antd";
import AddToDos from "../pages/ToDos/AddToDos";
import EditToDos from "../pages/ToDos/EditToDos";
import { useHistory, Link } from "react-router-dom";

import {
  personList,
  personCreate,
  createTaskAPI,
  listTasksAPI,
  retrieveTaskAPI,
  updateTaskAPI,
  deleteTaskAPI,
} from "../network/api";
import { create } from "object-path";

export default function Todo(props) {
  let history = useHistory();
  const [isModal, setIsModal] = useState(false);
  const [isEditModal, setIsEditModal] = useState(false);
  const [ID, setID] = useState("");
  const [userList, setUserList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [taskList, setTaskList] = useState([]);
  const [state, setState] = useState({
    project: props.match.params.id,
    who: "",
    whom: "",
    title: "",
    subheading: "",
    input: "",
  });
  let enduser = localStorage.getItem("enduser");
  const changeWho = (evt) => {
    if (evt.__isNew__) {
      personCreate({ name: evt.value, end_user: enduser }).then((res) => {
        if (res && res.status === 200) {
          setState({
            ...state,
            who: { label: res.data.name, value: res.data.id },
          });
          listPerson();
        }
      });
    } else {
      setState({ ...state, who: evt });
    }
  };
  const changeWhom = (evt) => {
    if (evt.__isNew__) {
      personCreate({ name: evt.value, end_user: enduser }).then((res) => {
        if (res && res.status === 200) {
          setState({
            ...state,
            whom: { label: res.data.name, value: res.data.id },
          });
          listPerson();
        }
      });
    } else {
      setState({ ...state, whom: evt });
    }
  };

  const handleCancel = () => {
    setIsModal(false);
    setIsEditModal(false);
    setState({
      project: props.match.params.id,
      who: "",
      whom: "",
      title: "",
      subheading: "",
    });
  };
  const gridStyle = {
    width: "100%",
    textAlign: "center",
  };
  const listingTasks = () => {
    setLoading(true);
    listTasksAPI({ project_id: props.match.params.id }).then((res) => {
      if (res && res.status === 200) {
        setTaskList(res.data.results);
        setLoading(false);
      }
    });
  };
  const listPerson = () => {
    personList({ end_user: enduser }).then((res) => {
      if (res && res.status === 200) {
        setUserList(res.data.results);
      }
    });
  };
  useEffect(() => {
    listPerson();
    listingTasks();
  }, []);
  const addTask = () => {
    if (!state.who) {
      message.error("誰を選択してください");
    } else if (!state.whom) {
      message.error("誰を選択してください");
    } else if (!state.title) {
      message.error("タスク見出しを選択してください");
    } else {
      let payload = {
        ...state,
        who: state.who.value,
        whom: state.whom.value,
      };
      createTaskAPI(payload).then((res) => {
        if (res && res.status === 200) {
          setIsModal(false);
          listingTasks();

          setState({
            project: props.match.params.id,
            who: "",
            whom: "",
            title: "",
            subheading: "",
          });
          message.success("タスクが正常に作成されました");
        }
      });
    }
  };

  const getTask = (id) => {
    retrieveTaskAPI(id).then((res) => {
      if (res && res.status === 200) {
        setState({
          project: props.match.params.id,
          who: res.data.data[0].who,
          whom: res.data.data[0].whom,
          title: res.data.data[0].title,
          subheading: res.data.data[0].subheading,
        });
        setIsEditModal(true);
        setID(res.data.data[0].id);
      }
    });
  };

  const updateTask = () => {
    if (!state.who) {
      message.error("誰を選択してください");
    } else if (state.whom.length === 0) {
      message.error("誰を選択してください");
    } else if (!state.title) {
      message.error("タスク見出しを選択してください");
    } else {
      let payload = {
        ...state,
        who: state.who.value,
        whom: state.whom.value,
      };
      updateTaskAPI(ID, payload).then((res) => {
        if (res && res.status === 200) {
          setIsEditModal(false);
          listingTasks();
          setState({
            project: props.match.params.id,
            who: "",
            whom: "",
            title: "",
            subheading: "",
          });
          message.success("タスクが正常に更新されました");
        }
      });
    }
  };

  const deleteTask = (id) => {
    deleteTaskAPI(id).then((res) => {
      if (res.status === 204) {
        message.success("タスクが正常に削除されました");
        listingTasks();
      }
    });
  };
  const confirm = (id) => {
    deleteTask(id);
  };
  return (
    <Container>
      <Row
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "2rem",
          fontWeight: "bolder",
        }}
      >
        <Col md={4} className="col-lg-4">
          <LeftCircleOutlined
            onClick={() => history.go(-1)}
            style={{
              fontSize: "2rem",
              fontWeight: "bolder",
            }}
          />
        </Col>
        <Col md={4} className="col-lg-4" style={{ textAlign: "center" }}>
          業務内容の登録
        </Col>
        <Col md={4} className="col-lg-4" style={{ textAlign: "right" }}>
          {" "}
          <Button
            style={{ borderRadius: "48px" }}
            onClick={() => setIsModal(true)}
          >
            <PlusOutlined style={{ color: "primary", fontSize: "18px" }} />{" "}
            業務内容を追加
          </Button>
        </Col>
      </Row>

      <hr />
      <AddToDos
        isModal={isModal}
        handleCancel={handleCancel}
        state={state}
        setState={setState}
        addTask={addTask}
        userList={userList}
        changeWho={changeWho}
        changeWhom={changeWhom}
      />
      <EditToDos
        isEditModal={isEditModal}
        handleCancel={handleCancel}
        state={state}
        setState={setState}
        updateTask={updateTask}
        userList={userList}
        changeWho={changeWho}
        changeWhom={changeWhom}
      />
      <Row>
        {taskList && taskList.length > 0 ? (
          taskList
            .sort((a, b) => b.id - a.id)
            .map((task) => (
              <Col md={3} style={{ marginTop: "0.8rem" }}>
                <Card
                  actions={[
                    // <SettingOutlined key="setting" />,
                    <EditOutlined
                      key="edit"
                      onClick={() => getTask(task.id)}
                    />,
                    <Popconfirm
                      title="本当にこの業務内容を削除しますか？"
                      onConfirm={() => confirm(task.id)}
                      // onCancel={cancel}
                      okText="はい"
                      cancelText="いいえ"
                    >
                      <DeleteOutlined
                        key="ellipsis"
                        // onClick={() => deleteProject(d.id)}
                      />
                    </Popconfirm>,
                  ]}
                >
                  <p>
                    {task.who?.name} が {task.whom?.name} に {task.title}
                  </p>
                  {/* <Card.Grid style={gridStyle}>
                    <Badge
                      count={task.who?.name}
                      style={{ backgroundColor: "#a2a3b7" }}
                    />{" "}
                    <b>が </b>
                    <Badge
                      count={task.whom?.name}
                      style={{ backgroundColor: "#a2a3b7" }}
                    />
                    <b>に</b>
                  </Card.Grid>

                  <Card.Grid style={gridStyle}>
                    <h6>{task.title}</h6>
                    <p>{task.subheading}</p>
                  </Card.Grid> */}
                </Card>
              </Col>
            ))
        ) : (
          <p style={{ margin: "0 auto", marginTop: "6rem" }}>
            {loading ? (
              <span className="ml-3 spinner spinner-lg spinner-dark"></span>
            ) : (
              " 表示する業務内容がありません。"
            )}
          </p>
        )}
      </Row>
      <Row>
        <Button
          className="btn-primary"
          style={{ margin: "0 auto", marginTop: "30px" }}
          onClick={() => history.push(`/projectflow/${props.match.params.id}`)}
        >
          業務フローの整理へ
        </Button>
      </Row>
    </Container>
  );
}
