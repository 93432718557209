import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Row } from "react-bootstrap";
import "antd/dist/antd.css";
import { Upload, Skeleton, Switch, Card, Divider, Col } from "antd";
import { LeftCircleOutlined } from "@ant-design/icons";
import { retrieveProject } from "../network/api";

export function Project(props) {
  let history = useHistory();
  const [projectName, setProject] = useState("");
  const { id } = props.match.params;
  const viewProject = () => {
    history.push("/projectflow/" + id);
  };

  const viewTodo = () => {
    history.push("/Todo/" + id);
  };

  useEffect(() => {
    retrieveProject(id).then((res) => {
      if (res && res.status === 200) {
        setProject(res.data.name);
      }
    });
  }, []);

  const { Meta } = Card;
  return (
    <>
      <Row
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "2rem",
          fontWeight: "bolder",
        }}
      >
        <Col md={4} className="col-lg-4">
          {" "}
          <LeftCircleOutlined
            onClick={() => history.go(-1)}
            style={{
              fontSize: "2rem",
              fontWeight: "bolder",
            }}
          />
        </Col>
        <Col md={4} className="col-lg-4" style={{ textAlign: "center" }}>
          {" "}
          {projectName}
        </Col>
        <Col md={4} className="col-lg-4"></Col>
      </Row>
      <Divider orientation="left"></Divider>

      <Row>
        <Col md={4} className="col-lg-4">
          <Card
            onClick={viewTodo}
            bordered={false}
            style={{ cursor: "pointer" }}
          >
            <div>
              <img className="img-task" src="/media/misc/todos.png" />
            </div>
            <div style={{ marginTop: "30px", textAlign: "center" }}>
              <Meta
                title="業務内容の登録"
                // description="This is the description"
              />
            </div>
          </Card>
        </Col>
        <Col
          md={4}
          onClick={viewProject}
          className="col-lg-4"
          style={{ cursor: "pointer" }}
        >
          <Card bordered={false}>
            <div>
              <img className="img-task" src="/media/misc/flow.jpg" />
            </div>
            <div style={{ marginTop: "30px", textAlign: "center" }}>
              <Meta
                title="業務フローの整理"
                // description="This is the description"
              />
            </div>
          </Card>
        </Col>
        <Col
          md={4}
          className="col-lg-4"
          onClick={() => history.push("/table/" + id)}
          style={{ cursor: "pointer" }}
        >
          <Card bordered={false}>
            <div>
              <img className="img-task" src="/media/misc/table.png" />
            </div>
            <div style={{ marginTop: "30px", textAlign: "center" }}>
              <Meta
                title="課題の解決
                "
                // description="This is the description"
              />
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default Project;
