import React, { Suspense, lazy, useEffect } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { BuilderPage } from "./pages/BuilderPage";
import MyPage from "./pages/MyPage";
import Todo from "./pages/to-do";
import ProjectFlow from "./pages/ProjectFlow";
import { DashboardPage } from "./pages/DashboardPage";
import Project from "./pages/Project";
import TableTodo from "./pages/TableTodo";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "./modules/Auth/_redux/authRedux";
import { parseJwt } from "./components/UIhelpers";
import {
  differenceInSeconds,
  differenceInMinutes,
  fromUnixTime,
  isFuture,
  differenceInMilliseconds,
} from "date-fns";
import { isPast } from "date-fns/esm";

export default function BasePage() {
  const dispatch = useDispatch();
  // const { authToken } = useSelector((state) => state.auth);
  // const { exp } = parseJwt(authToken);

  // useEffect(() => {
  //   const expiryTime = differenceInMilliseconds(fromUnixTime(exp), new Date());
  //   if (isPast(fromUnixTime(exp))) {
  //     dispatch(actions.logout());
  //   } else
  //     setTimeout(() => {
  //       dispatch(actions.logout());
  //     }, expiryTime);

  //   console.log(expiryTime);
  // }, [exp]);

  useEffect(() => {
    const timer = setTimeout(() => {
      localStorage.clear();
      window.location.href = "/auth/login";
    }, 1800000);
    return () => clearTimeout(timer);
  });
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        {/* <ContentRoute path="/dashboard" component={DashboardPage} /> */}
        {/* <ContentRoute path="/builder" component={BuilderPage} /> */}
        <ContentRoute path="/dashboard" component={DashboardPage} />
        <ContentRoute path="/description" component={MyPage} />
        <ContentRoute path="/Todo/:id/" component={Todo} />
        <ContentRoute path="/projectflow/:id/" component={ProjectFlow} />
        <ContentRoute path="/table/:id/" component={TableTodo} />
        <ContentRoute path="/project/:id/" component={Project} />
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
