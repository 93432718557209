import React, { useEffect, useState } from "react";
import {
  Table,
  Input,
  Popconfirm,
  Form,
  Typography,
  Checkbox,
  Button,
  Modal,
} from "antd";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import {
  PlusOutlined,
  EditOutlined,
  LeftCircleOutlined,
} from "@ant-design/icons";
import { listTasksAPI, updateTaskAPI } from "../network/api";
import CreateAbleSelect from "react-select/creatable";
import { tagsList, createTagAPI } from "../network/api";
import { Space } from "antd";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { MDBContainer, MDBIframe } from "mdbreact";

export const ExportToExcel = ({ apiData, fileName }) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = (apiData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(apiData);
    ws["E1"] = {
      t: "s",
      v: "課題や解決の鍵となる主要なキーワードを３つ入力、または選んでください	",
    };
    ws["!merges"] = [
      {
        //Merge the first row of data[B1,C1,D1,E1]
        s: {
          //s is the beginning
          c: 5, //start column
          r: 0, //start value range
        },
        e: {
          //e end
          c: 7, //End column
          r: 0, //end range
        },
      },
    ];

    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <Button
      onClick={(e) => exportToCSV(apiData, fileName)}
      className="btn-primary"
      style={{ margin: "0 auto" }}
    >
      ダウンロード
    </Button>
  );
};

function warning() {
  Modal.warning({
    // title: "",
    content: "先にキーワードを追加してください",
    width: 327,
    bodyStyle: { height: "20px" },
    okText: "はい",
    onOk() {},
  });
}

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  setFieldsValue,
  children,
  ...restProps
}) => {
  // const [tags, setTags] = useState([]);
  // const tagsOptions = [];
  // const listTags = () => {
  //   tagsList().then((res) => {
  //     if (res && res.status === 200 && res.data?.results) {
  //       for (let i = 0; i < res.data.results.length; i++) {
  //         tagsOptions.push({
  //           label: res.data.results[i].name,
  //           value: res.data.results[i].id,
  //         });
  //         setTags(tagsOptions);
  //       }
  //     }
  //   });
  // };

  // useEffect(() => {
  //   listTags();
  // }, []);
  // const changeTag = (evt) => {
  //   if (evt.__isNew__) {
  //     createTagAPI({ name: evt.value }).then((res) => {
  //       if (res.status === 200) {
  //         listTags();
  //       }
  //     });
  //   }
  // };
  const { TextArea } = Input;
  const inputNode =
    inputType === "Checkbox" ? (
      <Checkbox />
    ) : inputType === "text" ? (
      <TextArea autoSize={{ minRows: 4, maxRows: 6 }} />
    ) : (
      <Input />
      // <CreateAbleSelect
      //   className="react-select w-100 react-1"
      //   classNamePrefix="react-select"
      //   name="form-field-name"
      //   isopen={true}
      //   maxMenuHeight={120}
      //   options={tags}
      //   onChange={(e) => changeTag(e)}
      // />
    );
  return (
    <td {...restProps}>
      {editing && inputType === "Checkbox" ? (
        <Form.Item
          name={dataIndex}
          valuePropName={inputType === "Checkbox" && "checked"}
          style={{
            margin: 0,
          }}
        >
          {inputNode}
        </Form.Item>
      ) : editing && inputType === "text" ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
        >
          {inputNode}
        </Form.Item>
      ) : editing && inputType !== "text" && inputType !== "Checkbox" ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
        >
          {inputNode}
        </Form.Item>
      ) : (
        // <CreateAbleSelect
        //   placeholder="Select or Create New"
        //   className="react-select w-100 react-1"
        //   classNamePrefix="react-select"
        //   name="form-field-name"
        //   options={tags}
        //   // onChange={changeTag}
        // />
        children
      )}
    </td>
  );
};

const TableTodo = (props) => {
  const history = useHistory();
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState("");
  const isEditing = (record) => record.id === editingKey;
  const [loading, setLoading] = useState(false);
  const [taskList, setTaskList] = useState([]);
  const [excelData, setExcelData] = useState([]);
  const [isModel, setIsModel] = useState(false);
  const [ID, setID] = useState("");
  const [method, setMethod] = useState("");

  const handleCancel = () => {
    setIsModel(false);
  };
  const listingTasks = () => {
    setLoading(true);
    listTasksAPI({ project_id: props.match.params.id }).then((res) => {
      if (res && res.status === 200) {
        setTaskList(res.data.results);
        setLoading(false);
        const data = [];
        let apiData = res.data.results;
        for (let i = 0; i < res.data.results.length; i++) {
          data.push({
            現在のワークフロー: `${apiData[i].who.name} が ${apiData[i].whom.name} に ${apiData[i].title}`,
            課題の有無: apiData[i].is_error ? "True" : "False",
            "課題に感じている点は何ですか？": `${apiData[i]?.issue || " "}`,
            "それはなぜですか？": `${apiData[i]?.reason || " "}`,
            "どう改善したいですか？": `${apiData[i]?.how || ""}`,
            "課題や解決の鍵となる主要なキーワードを３つ入力、または選んでください":
              apiData[i].tag1,
            tag2: `${apiData[i]?.tag2 || ""}`,
            tag3: `${apiData[i]?.tag3 || ""}`,
            "ヒント検索	": "検索",
            導入候補の機能: `${apiData[i]?.method || " "}`,
          });
        }
        setExcelData(data);
      }
    });
  };

  useEffect(() => {
    listingTasks();
  }, []);

  const edit = (record) => {
    form.setFieldsValue({
      id: record.id,
      is_error: record.is_error,
      issue: record.issue,
      reason: record.reason,
      method: record.method,
      tag1: record.tag1,
      tag2: record.tag2,
      tag3: record.tag3,
      ...record,
    });
    setEditingKey(record.id);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const updateTask = (id, data) => {
    updateTaskAPI(id, data).then((res) => {
      setIsModel(false);
      listingTasks();
    });
  };

  const save = async (id) => {
    try {
      const row = await form.validateFields();
      // tagsList().then((res) => {
      //   if (res && res.status === 200) {
      const newData = [...taskList];
      const index = newData.findIndex((item) => id === item.id);
      if (index > -1) {
        const item = newData[index];
        // let tag1 =
        //   typeof row?.tag1.value === "string"
        //     ? res.data?.results.filter((d) => d.name === row.tag1.value)
        //     : "";
        // let tag2 =
        //   typeof row?.tag2?.value === "string"
        //     ? res.data?.results.filter((d) => d.name === row.tag2.value)
        //     : "";
        // let tag3 =
        //   typeof row?.tag3?.value === "string"
        //     ? res.data?.results.filter((d) => d.name === row.tag3.value)
        //     : "";
        // row["tag1"] = typeof tag1 === "object" ? tag1[0].id : row.tag1?.value;
        // row["tag2"] = typeof tag2 === "object" ? tag2[0].id : row.tag2?.value;
        // row["tag3"] = typeof tag3 === "object" ? tag3[0].id : row.tag3?.value;

        updateTask(item.id, row);
        newData.splice(index, 1, { ...item, ...row });
        setTaskList(newData);
        setEditingKey("");
      } else {
        setEditingKey("");
      }
      // }
      // });
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const columns = [
    {
      title: "現在のワークフロー",
      dataIndex: "title",
      // fixed: "left",
      width: 140,
      render: (_, record) => (
        <span>
          {" "}
          {record.who?.name} が {record.whom?.name} に {record.title}
        </span>
      ),
    },
    {
      title: <EditOutlined style={{ color: "#1890ff", textAlign: "center" }} />,
      dataIndex: "operation",
      // fixed: "right",
      width: 60,
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record.id)}
              style={{
                marginRight: 8,
              }}
            >
              保存
            </Typography.Link>
            <Popconfirm
              title="編集を中止しますか？"
              onConfirm={cancel}
              okText="はい"
              cancelText="いいえ"
            >
              <a> 中止</a>
            </Popconfirm>
          </span>
        ) : (
          <EditOutlined
            style={{ color: "#1890ff" }}
            disabled={editingKey !== ""}
            onClick={() => edit(record)}
          />
        );
      },
    },
    {
      title: "課題の有無",
      dataIndex: "is_error",
      width: 60,
      render: (text) => (text ? "有" : "無"),
      editable: true,
    },
    {
      title: "課題に感じている点は何ですか？",
      dataIndex: "issue",
      editable: true,
      width: 110,
    },
    {
      title: "それはなぜですか？",
      dataIndex: "reason",
      editable: true,
      width: 110,
    },
    {
      title: "どう改善したいですか？",
      dataIndex: "how",
      editable: true,
      width: 110,
    },
    {
      title:
        "課題や解決の鍵となる主要なキーワードを３つ入力、または選んでください",
      dataIndex: "tag1",
      editable: true,
      width: 110,
      colSpan: 3,
      // render: (text) => (text ? text?.label : ""),
    },
    {
      title: "",
      colSpan: 0,
      editable: true,
      dataIndex: "tag2",
      width: 110,
      // render: (text) => (text ? text?.label : ""),
    },
    {
      title: "",
      colSpan: 0,
      editable: true,
      dataIndex: "tag3",
      width: 110,
      // render: (text) => (text ? text?.label : ""),
    },
    {
      title: "ヒント検索",
      dataIndex: "tags",
      width: 70,
      render: (_, record) =>
        record?.tag1 || record?.tag2 || record?.tag3 ? (
          <Button
            style={{ width: "60px" }}
            onClick={(e) =>
              window.open(
                `https://www.google.com/search?q=${record.tag1}+${
                  record?.tag2 || ""
                }+${record?.tag3 || ""}+ツール`,
                "_blank"
              )
            }
          >
            検索
          </Button>
        ) : (
          <Space>
            <Button style={{ width: "60px" }} onClick={warning}>
              検索
            </Button>
          </Space>
        ),
    },
    {
      title: "導入候補の機能",
      dataIndex: "method",
      // fixed: "right",
      width: 80,
      // editable: true,
      render: (_, record) => (
        <span style={{ textAlign: "center" }}>
          {record.method ? (
            <>
              <span>{record.method.slice(0, 30)}</span>
              <Typography.Link
                onClick={() => {
                  setIsModel(true);
                  setID(record.id);
                  setMethod(record.method);
                }}
              >
                ...全文
              </Typography.Link>
            </>
          ) : (
            <PlusOutlined
              style={{ color: "#1890ff" }}
              onClick={() => {
                setIsModel(true);
                setID(record.id);
                setMethod(record.method);
              }}
            />
          )}
        </span>
      ),
    },
  ];
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType:
          col.dataIndex === "is_error"
            ? "Checkbox"
            : col.dataIndex === "tag1" ||
              col.dataIndex === "tag2" ||
              col.dataIndex === "tag3"
            ? "input"
            : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });
  const { TextArea } = Input;
  return (
    <Form form={form} component={false}>
      <Row
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "2rem",
          fontWeight: "bolder",
        }}
      >
        <Col md={4} className="col-lg-4">
          <LeftCircleOutlined
            onClick={() => history.go(-1)}
            style={{
              fontSize: "2rem",
              fontWeight: "bolder",
            }}
          />
        </Col>
        <Col md={4} className="col-lg-4" style={{ textAlign: "center" }}>
          課題の解決
        </Col>
        <Col md={4} className="col-lg-4" style={{ textAlign: "right" }}>
          <ExportToExcel apiData={excelData} fileName={"ダウンロード"} />
        </Col>
      </Row>
      <hr />
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        rowClassName={(record, index) => record.is_error && "table-error-rows"}
        bordered
        dataSource={taskList}
        columns={mergedColumns}
        pagination={{ pageSize: 30 }}
        scroll={{ y: 500 }}
      />
      <br />

      <Row>
        <Col md={2} className="col-lg-2"></Col>
        <Col md={9} className="col-lg-9">
          <iframe
            frameBorder="0"
            title="contact-iframe"
            width="100%"
            style={{ backgroundColor: "white" }}
            height="360"
            src="https://poc324.s3.ap-northeast-1.amazonaws.com/contact.html"
          ></iframe>
        </Col>
        <Col md={1} className="col-lg-1"></Col>
      </Row>
      <Modal
        title="導入候補の機能"
        visible={isModel}
        footer={null}
        onCancel={handleCancel}
      >
        <TextArea
          value={method}
          onChange={(e) => {
            setMethod(e.target.value);
          }}
          autoSize={{ minRows: 6, maxRows: 12 }}
        />

        <br />
        <br />
        <Button onClick={() => updateTask(ID, { method })}>保存</Button>
      </Modal>
    </Form>
  );
};

export default TableTodo;
