import React from "react";
import "antd/dist/antd.css";
import { Button, Modal, Form, Select, Input } from "antd";
import CreateAbleSelect from "react-select/creatable";

export default function EditToDos(props) {
  const { TextArea } = Input;
  const { state, setState, updateTask, userList, changeWho, changeWhom } =
    props;
  const color = { color: "red" };
  const userOptions = [];
  for (let index = 0; index < userList.length; index++) {
    const { id, name } = userList[index];
    userOptions.push({ label: name, value: id });
  }
  return (
    <Modal
      title="業務内容を更新"
      visible={props.isEditModal}
      footer={null}
      onCancel={props.handleCancel}
    >
      <Form method="post" layout="vertical" /*onFinish={onFinish}*/>
        <label>
          <span style={color}>* </span>誰が（何が）
        </label>
        <CreateAbleSelect
          placeholder="入力・又は選択してください"
          className="react-select w-100 react-1"
          classNamePrefix="react-select"
          name="form-field-name"
          value={state.who}
          onChange={(e) => changeWho(e)}
          options={userOptions}
        />
        <label>
          <span style={color}>* </span>誰に（何に）
        </label>{" "}
        <CreateAbleSelect
          placeholder="入力・又は選択してください"
          className="react-select w-100 react-1"
          classNamePrefix="react-select"
          name="form-field-name"
          value={state.whom}
          onChange={(e) => changeWhom(e)}
          options={userOptions}
        />
        <br />
        <label>
          <span style={color}>* </span> 何をする？
        </label>
        <br />
        <TextArea
          rows="4"
          onChange={(e) => setState({ ...state, title: e.target.value })}
          value={state.title}
        />
        <br /> <br />
        <label> 補足説明 </label>
        <br />
        <input
          className="ant-input"
          onChange={(e) => setState({ ...state, subheading: e.target.value })}
          value={state.subheading}
        />
      </Form>
      <br />
      <Button onClick={updateTask}>更新</Button>
    </Modal>
  );
}
