import React, { useState, useEffect } from "react";
import "antd/dist/antd.css";
import ReactQuill from "react-quill";
import { Button, Modal } from "antd";
import "react-quill/dist/quill.snow.css";

export function EditProject(props) {
  const { state, setState, updateProject } = props;
  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "code"],
      ["clean"],
    ],
  };
  const color = { color: "red" };
  return (
    <Modal
      title="プロジェクトの詳細を更新"
      visible={props.isEditModal}
      onOk={props.handleOk}
      footer={null}
      onCancel={props.handleCancel}
    >
      <form onSubmit={() => updateProject()}>
        <label>
          <span style={color}>* </span>プロジェクト名
        </label>
        <br />
        <input
          className="ant-input"
          name="name"
          onChange={(e) => setState({ ...state, name: e.target.value })}
          value={state.name}
        />
        <label>
          <span style={color}> </span>詳細
        </label>
        <br />

        <ReactQuill
          className="question-form__rich-text"
          theme="snow"
          value={state.description}
          modules={modules}
          style={{ height: "120px" }}
          onChange={(e) =>
            setState((state) => ({ name: state.name, description: e }))
          }
        />
        <br />

        <Button style={{ marginTop: "60px" }} onClick={updateProject}>
          更新
        </Button>
      </form>
    </Modal>
  );
}
