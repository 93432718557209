import React, { useMemo } from "react";
import objectPath from "object-path";
import { useHistory } from "react-router-dom";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { Topbar } from "./Topbar";
import { HeaderMenuWrapper } from "./header-menu/HeaderMenuWrapper";
import { AnimateLoading } from "../../../_partials/controls";
import { toAbsoluteUrl } from "../../../_helpers";

export function Header() {
  const uiService = useHtmlClassService();
  let history = useHistory();

  const layoutProps = useMemo(() => {
    return {
      headerClasses: uiService.getClasses("header", true),
      headerAttributes: uiService.getAttributes("header"),
      headerContainerClasses: uiService.getClasses("header_container", true),
      menuHeaderDisplay: objectPath.get(
        uiService.config,
        "header.menu.self.display"
      ),
    };
  }, [uiService]);

  return (
    <>
      {/*begin::Header*/}
      <div
        style={{ left: "0px" }}
        className={`header ${layoutProps.headerClasses}`}
        id="kt_header"
        {...layoutProps.headerAttributes}
      >
        {/*begin::Container*/}
        <div
          className={` ${layoutProps.headerContainerClasses} d-flex align-items-stretch justify-content-between`}
        >
          <AnimateLoading />
          <div
            className="home-icons"
            onClick={() => history.push("/")}
            // style={{ width: "13%" }}
          >
            <i className="fa fa-home home-icon"></i>
          </div>

          {/*begin::Header Menu Wrapper*/}
          {layoutProps.menuHeaderDisplay && <HeaderMenuWrapper />}
          {/* {!layoutProps.menuHeaderDisplay && <div />} */}
          <img
            className="custom-logo"
            style={{ margin: "8px auto", height: "50px" }}
            src={toAbsoluteUrl("/media/logo.png")}
            alt="logo here"
          />
          {/* <h2 style={{ margin: "21px" }}> 業改革命 dfdgdf</h2> */}
          {/*end::Header Menu Wrappe  r*/}
          {/*begin::Topbar*/}
          <Topbar />
          {/*end::Topbar*/}
        </div>
        {/*end::Container*/}
      </div>
      {/*end::Header*/}
    </>
  );
}
