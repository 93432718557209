import React, { useEffect, useState } from "react";
// Bootstrap for react
import { Container } from "react-bootstrap";
import { useHistory, Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";

import {
  ExclamationCircleOutlined,
  SettingOutlined,
  LeftCircleOutlined,
  EditOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Card,
  Badge,
  Popconfirm,
  Divider,
  message,
  Tooltip,
  Button,
  Input,
} from "antd";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  listTasksAPI,
  retrieveTaskAPI,
  updateTaskAPI,
  personList,
  personCreate,
} from "../network/api";
import EditToDos from "./ToDos/EditToDos";
import ErrorReport from "./ErrorReport";

export default function ProjectFlow(props) {
  let history = useHistory();
  const [taskList, setTaskList] = useState();
  const [loading, setLoading] = useState(false);
  const [isEditModal, setIsEditModal] = useState(false);
  const [isErrorModal, setIsErrorModal] = useState(false);
  const [ID, setID] = useState("");
  const [isError, setIsError] = useState(false);
  const [userList, setUserList] = useState([]);

  const [state, setState] = useState({
    project: props.match.params.id,
    who: "",
    whom: [],
    title: "",
    subheading: "",
    input: "",
  });
  const [errorState, setErrorState] = useState({
    is_error: false,
    issue: "",
    tags: [],
    reason: "",
    how: "",
    method: "",
    input: "",
  });
  const handleCancel = () => {
    setIsEditModal(false);
    setIsErrorModal(false);
    setID("");
  };

  const changeWho = (evt) => {
    if (evt.__isNew__) {
      personCreate({ name: evt.value }).then((res) => {
        if (res.status === 200) {
          setState({
            ...state,
            who: { label: res.data.name, value: res.data.id },
          });
          listPerson();
        }
      });
    } else {
      setState({ ...state, who: evt });
    }
  };
  const changeWhom = (evt) => {
    if (evt.__isNew__) {
      personCreate({ name: evt.value }).then((res) => {
        if (res.status === 200) {
          setState({
            ...state,
            whom: { label: res.data.name, value: res.data.id },
          });
          listPerson();
        }
      });
    } else {
      setState({ ...state, whom: evt });
    }
  };
  const listingTasks = () => {
    setLoading(true);
    listTasksAPI({ project_id: props.match.params.id }).then((res) => {
      if (res && res.status === 200) {
        setTaskList(res.data.results);
        setLoading(false);
      }
    });
  };
  const listPerson = () => {
    personList().then((res) => {
      if (res && res.status === 200) {
        setUserList(res.data.results);
      }
    });
  };
  useEffect(() => {
    listPerson();
    listingTasks();
  }, []);

  const getTask = (id) => {
    retrieveTaskAPI(id).then((res) => {
      if (res && res.status === 200) {
        setState({
          project: props.match.params.id,
          who: res.data.data[0].who,
          whom: res.data.data[0].whom,
          title: res.data.data[0].title,
          subheading: res.data.data[0].subheading,
        });
        setIsEditModal(true);
        setID(res.data.data[0].id);
      }
    });
  };

  const updateTask = () => {
    if (!state.who) {
      message.error("Please select Who");
    } else if (!state.whom) {
      message.error("Please select Whom");
    } else if (!state.title) {
      message.error("Please select Task heading");
    } else {
      let payload = {
        ...state,
        who: state.who.value,
        whom: state.whom.value,
      };
      updateTaskAPI(ID, payload).then((res) => {
        if (res && res.status === 200) {
          setIsEditModal(false);
          listingTasks();

          setState({
            project: props.match.params.id,
            who: "",
            whom: [],
            title: "",
            subheading: "",
          });
          message.success("Task updated successfully");
        }
      });
    }
  };

  function handleOnDragEnd(result) {
    if (!result.destination) return;
    const items = Array.from(taskList);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setTaskList(items);
    message.success("移動が完了しました");
    console.log("flowwwwwwwwwwwwwww", items);
    for (let i = 0; i < items.length; i++) {
      updateTaskAPI(items[i].id, { priority: i + 1 });
    }
    // updateTaskAPI(reorderedItem.id, {
    //   priority: result.destination.index + 1,
    // });
    // let secondItem = items[result.source.index];
    // updateTaskAPI(secondItem.id, { priority: result.source.index + 1 }).then(
    //   (res) => {
    //     if (res && res.status === 200) {
    //       message.success("移動が完了しました");
    //     }
    //   }
    // );
    // listingTasks();
    // listingTasks();
  }
  const errorConfirm = (id, error) => {
    updateTaskAPI(id, { is_error: !error }).then((res) => {
      if (res && res.status === 200) {
        listingTasks();
        message.success(" 処理が完了しました");
      }
    });
  };
  // const errorConfirm = () => {
  //   updateTaskAPI(ID, errorState).then((res) => {
  //     if (res && res.status === 200) {
  //       setIsErrorModal(false);
  //       setID("");
  //       listingTasks();
  //       message.success("タスクが正常にエラーに移行");
  //       setErrorState({
  //         is_error: false,
  //         issue: "",
  //         tags: [],
  //         reason: "",
  //         how: "",
  //         method: "",
  //         input: "",
  //       });
  //     }
  //   });
  // };
  const errorResolve = () => {
    updateTaskAPI(ID, { is_error: false }).then((res) => {
      if (res && res.status === 200) {
        setIsErrorModal(false);
        setID("");
        setIsError(false);
        listingTasks();
        message.success("エラーは正常に解決されました");
        setErrorState({
          is_error: false,
          issue: "",
          tags: [],
          reason: "",
          method: "",
          input: "",
        });
      }
    });
  };
  const isAutomatedConfirm = (id) => {
    updateTaskAPI(id, { is_automate: true }).then((res) => {
      if (res && res.status === 200) {
        listingTasks();
        message.success("Task Automated successfully");
      }
    });
  };

  const getItemStyle = (isDragging, draggableStyle) => {
    const { transform } = draggableStyle;
    let activeTransform = {};
    if (transform) {
      activeTransform = {
        transform: `translate(0, ${transform.substring(
          transform.indexOf(",") + 1,
          transform.indexOf(")")
        )})`,
      };
    }
    return {
      // styles we need to apply on draggables

      ...draggableStyle,
      ...activeTransform,
    };
  };

  return (
    <Container>
      <Row
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "2rem",
          fontWeight: "bolder",
        }}
      >
        <Col md={4} className="col-lg-4">
          <LeftCircleOutlined
            onClick={() => history.go(-1)}
            style={{
              fontSize: "2rem",
              fontWeight: "bolder",
            }}
          />
        </Col>
        <Col md={4} className="col-lg-4" style={{ textAlign: "center" }}>
          業務フローの整理
        </Col>
        <Col md={4} className="col-lg-4" style={{ textAlign: "right" }}>
          <Button
            className="btn-primary"
            onClick={() => history.push(`/table/${props.match.params.id}`)}
          >
            課題の解決へ
          </Button>{" "}
        </Col>
      </Row>
      <hr />
      {/* <Divider orientation="left"></Divider> */}
      <EditToDos
        isEditModal={isEditModal}
        handleCancel={handleCancel}
        state={state}
        setState={setState}
        updateTask={updateTask}
        userList={userList}
        changeWho={changeWho}
        changeWhom={changeWhom}
      />
      <ErrorReport
        isErrorModal={isErrorModal}
        isError={isError}
        handleCancel={handleCancel}
        errorState={errorState}
        setErrorState={setErrorState}
        errorResolve={errorResolve}
        errorConfirm={errorConfirm}
      />
      {/* background-color: #fffdd0; */}
      <Row>
        <div className="col-md-4"></div>
        <div className="col-md-4">
          <div style={{ alignItems: "center", justifyContent: "center" }}>
            {taskList && taskList.length > 0 ? (
              <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="characters">
                  {(provided, snapshot) => (
                    <ul
                      // className="characters"
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      style={{
                        // display: "inline",
                        // margin: "0 auto",
                        listStyle: "none",
                      }}
                    >
                      {taskList &&
                        taskList
                          // .sort((a, b) => a.priority - b.priority)
                          .map((task, index) => (
                            <Draggable
                              position={{ x: 0, y: 0 }}
                              key={task.title}
                              draggableId={task.title}
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <li
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={getItemStyle(
                                    snapshot.isDragging,
                                    provided.draggableProps.style
                                  )}
                                >
                                  {/* {index > 0 && (
                                    <div
                                      style={{
                                        fontSize: "30px",
                                        fontWeight: "bolder",
                                        marginTop: "-36px",
                                        textAlign: "center",
                                      }}
                                    >
                                      &#8595;
                                    </div>
                                  )} */}
                                  <Card
                                    // style={{ marginTop: "0.5rem" }}
                                    title={
                                      <EditOutlined
                                        style={{
                                          marginTop: "-15px",
                                          marginLeft: "8px",
                                        }}
                                        key="edit"
                                        onClick={() => getTask(task.id)}
                                      />
                                    }
                                    extra={
                                      <Button
                                        style={{
                                          display: "flex",
                                          borderRadius: "3.8rem",
                                          marginRight: "3px",
                                        }}
                                        onClick={() =>
                                          errorConfirm(task.id, task.is_error)
                                        }
                                      >
                                        <label>課題を感じる</label>
                                        <Input
                                          class
                                          style={{
                                            marginTop: "4px",
                                            width: "18px",
                                          }}
                                          type="checkbox"
                                          checked={task.is_error}
                                          value={task.is_error}
                                          // onClick={() => {
                                          // setIsErrorModal(true);
                                          // setID(task.id);
                                          // setIsError(!task.is_error);
                                          // task.is_error
                                          //   ? setErrorState({
                                          //       is_error: task.is_error,
                                          //       issue: task.issue,
                                          //       tags: [...task.tags],
                                          //       reason: task.reason,
                                          //       how: task.how,
                                          //       method: task.method,
                                          //       input: "",
                                          //     })
                                          //   : setErrorState({
                                          //       is_error: false,
                                          //       issue: "",
                                          //       tags: [],
                                          //       reason: "",
                                          //       method: "",
                                          //       input: "",
                                          //     });
                                          // }}
                                        />
                                      </Button>
                                    }
                                    className={
                                      // task.is_automate
                                      //   ? "automate-card"
                                      // :
                                      task.is_error ? "error-card" : ""
                                    }
                                    // actions={[
                                    //   <EditOutlined
                                    //     key="edit"
                                    //     onClick={() => getTask(task.id)}
                                    //   />,

                                    //   <Tooltip
                                    //     title="このタスクをエラーにシフト"
                                    //     color="#e292a0"
                                    //   >
                                    //     <ExclamationCircleOutlined
                                    //       onClick={() => {
                                    //         setIsErrorModal(true);
                                    //         setID(task.id);
                                    //         setIsError(task.is_error);
                                    //         task.is_error
                                    //           ? setErrorState({
                                    //               is_error: task.is_error,
                                    //               issue: task.issue,
                                    //               tags: [...task.tags],
                                    //               reason: task.reason,
                                    //               method: task.method,
                                    //               input: "",
                                    //             })
                                    //           : setErrorState({
                                    //               is_error: false,
                                    //               issue: "",
                                    //               tags: [],
                                    //               reason: "",
                                    //               method: "",
                                    //               input: "",
                                    //             });
                                    //       }}
                                    //       style={{ color: "#e292a0" }}
                                    //     />
                                    //   </Tooltip>,

                                    //   // <Popconfirm
                                    //   //   title="Are you sure to Automate this task?"
                                    //   //   onConfirm={() =>
                                    //   //     isAutomatedConfirm(task.id)
                                    //   //   }
                                    //   //   // onCancel={cancel}
                                    //   //   okText="Yes"
                                    //   //   cancelText="No"
                                    //   // >
                                    //   // <Tooltip
                                    //   //   title="Shift this task into Automation"
                                    //   //   color="#ffa800"
                                    //   // >
                                    //   //   <SettingOutlined
                                    //   //     style={{ color: "#ffa800" }}
                                    //   //   />
                                    //   // </Tooltip>
                                    //   // </Popconfirm>,
                                    // ]}
                                  >
                                    <div
                                      style={{
                                        textAlign: "center",
                                        marginTop: "-1.5rem",
                                      }}
                                    >
                                      <Badge
                                        count={index + 1}
                                        style={{
                                          backgroundColor: "#187de4",
                                        }}
                                      />
                                    </div>

                                    <p>
                                      {task.who?.name} が {task.whom?.name} に{" "}
                                      {task.title}
                                    </p>
                                  </Card>
                                  <br />
                                </li>
                              )}
                            </Draggable>
                          ))}
                    </ul>
                  )}
                </Droppable>
              </DragDropContext>
            ) : (
              <p
                style={{
                  margin: "0 auto",
                  marginTop: "6rem",
                  textAlign: "center",
                }}
              >
                {loading ? (
                  <span
                    className="ml-3 spinner spinner-lg spinner-dark"
                    // style={{ left: "50%", top: "50%" }}
                  ></span>
                ) : (
                  "タスクが見つかりません "
                )}
              </p>
            )}
          </div>
        </div>
      </Row>
    </Container>
  );
}
