import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import GoogleLogin from "react-google-login";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { login, socialAuthRegisteration } from "../../../network/api";

/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

const initialValues = {
  email: "",
  password: "",
};

function Login(props) {
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    password: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      setTimeout(() => {
        login({ username: values.email, password: values.password })
          .then(({ data: { access, enduser, name } }) => {
            disableLoading();
            localStorage.setItem("token", access);
            localStorage.setItem("enduser", enduser);
            localStorage.setItem("name", name);
            props.login(access);
          })
          .catch(() => {
            setStatus(
              intl.formatMessage({
                id: "AUTH.VALIDATION.INVALID_LOGIN",
              })
            );
          })
          .finally(() => {
            disableLoading();
            setSubmitting(false);
          });
      }, 1000);
    },
  });

  const responseGoogle = (response) => {
    console.log("", response);
    socialAuthRegisteration({
      token: response.accessToken,
    }).then(({ data: { access, enduser_id, name } }) => {
      disableLoading();
      localStorage.setItem("token", access);
      localStorage.setItem("enduser", enduser_id);
      localStorage.setItem("name", name);
      props.login(access);
    });
  };

  return (
    <div
      className="login-form login-signin"
      id="kt_login_signin_form"
      style={{
        backgroundColor: "#344d88",
        color: "white",
        // marginTop: "-7.4rem",
        borderRadius: "18px",
        // width: "27%",
      }}
    >
      {/* begin::Head */}
      {/* <div
        className="d-flex  mb-lg-10"
        style={{ justifyContent: "space-between" }}
      >
        <h3>
          <FormattedMessage id="サインイン" />
        </h3>
        <Link
          to="/auth/registration"
          className="font-weight-bold ml-2"
          id="kt_login_signup"
          style={{ lineHeight: "1.5" }}
        >
          アカウントが存在しません
        </Link>
      </div> */}
      {/* end::Head */}

      {/*begin::Form*/}
      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework"
      >
        {formik.status && (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        )}
        {/* <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="ユーザー名"
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "email"
            )}`}
            name="email"
            {...formik.getFieldProps("email")}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="パスワード"
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "password"
            )}`}
            name="password"
            {...formik.getFieldProps("password")}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          ) : null}
        </div>
        */}
        {/*<<div className="form-group d-flex flex-wrap justify-content-between align-items-center">
          <Link
            to="/auth/forgot-password"
            className="text-dark-50 text-hover-primary my-3 mr-2"
            id="kt_login_forgot"
          >
            <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
          </Link> */}
        {/* <button
          id="kt_login_signin_submit"
          type="submit"
          disabled={formik.isSubmitting}
          style={{ width: "100%" }}
          className={`btn btn-primary  font-weight-bold px-9 py-4 my-3`}
        >
          <span>
            <FormattedMessage id="AUTH.LOGIN.BUTTON" />
          </span>
          {loading && <span className="ml-3 spinner spinner-white"></span>}
        </button>
        <br />
        <br />
        <div className="font-weight-bold text-center text-dark-50">
          別のアカウントでサインイン
        </div> */}
        <p
          style={{ lineHeight: "24px", textAlign: "center", fontSize: "12px" }}
        >
          {" "}
          業務見直しツールをご利用いただくには、Googleアカウントが必要となります。お手数ですが、
          Googleアカウントをご用意の上、以下のボタンからサインインしてください。サインインした場合、
          利用規約に同意したものとみなします。
        </p>

        <a
          href="#"
          className="link-adj"
          onClick={() =>
            window.open(
              "https://gyokai.jp/tool_terms",
              "myWindow",
              "width=600,height=500"
            )
          }
        >
          利用規約
        </a>
        <div className="google-btn">
          <GoogleLogin
            className="google-btn"
            clientId="428625333719-3n5a93qmee7psaghlu7bto6rn4v46533.apps.googleusercontent.com"
            buttonText="Sign in with Google"
            onSuccess={responseGoogle}
            onFailure={responseGoogle}
            cookiePolicy="single_host_origin"
          />
        </div>
        {/* <button className="google-in">
          {" "}
          <img
            class="google-icon"
            src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"
          />{" "}
          Google
        </button> */}
        {/* </div> */}
      </form>
      {/*end::Form*/}
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Login));
