// // import React from "react";
// // import { useSubheader } from "../../_metronic/layout";

// // export const MyPage = () => {
// //   const suhbeader = useSubheader();
// //   suhbeader.setTitle("My Custom title");

// //   return <>Settings Page</>;
// // };
// // import React, { useState } from "react";
// // // import "./styles.css";
// // import "react-quill/dist/quill.snow.css";
// // import TextEditor from "../components/TextEditor";

// // export function MyPage() {
// //   const [content, setContent] = useState("");
// //   return (
// //     <div className="App">
// //       <h1>My Editor</h1>
// //       <TextEditor
// //         name="content"
// //         content={content}
// //         onChange={content => setContent(content)}
// //       />
// //     </div>
// //   );
// // }

// import React from "react";
// import ReactDOM from "react-dom";
// import RichTextEditor from "../components/TextEditor";
// import * as Joi from "joi-browser";
// import { Formik, Field, Form as FormikForm } from "formik";
// import { ConfigProvider, Radio, Button, Typography } from "antd";
// import { RegisterSchema } from "./Validation";

// import "react-quill/dist/quill.core.css";
// import "react-quill/dist/quill.snow.css";
// import "antd/dist/antd.css";
// // import "./styles.css";

// export function MyPage() {
//   // const handleRichTextChange = value => setText({ text: value });

//   return (
//     <div className="App">
//       <h1>Hello CodeSandbox</h1>
//       <h2>Start editing to see some magic happen!</h2>
//       <p> And here is a RichTextEditor</p>
//       <Formik
//         initialValues={{
//           richtext: ""
//         }}
//         validationSchema={form => Joi.validate(form.values, RegisterSchema)}
//         onSubmit={(values, { setSubmitting }) => {
//           console.log("what I am submitting is: ", values);
//           setTimeout(() => {
//             alert(JSON.stringify(values, null, 2));
//             setSubmitting(false);
//           }, 300);
//         }}
//       >
//         {({ isSubmitting, isValid, setFieldValue }) => {
//           console.log(
//             `why isSubmitting: ${isSubmitting} and isValid: ${isValid} are doing me kolo ? `
//           );
//           return (
//             <FormikForm>
//               <Field name="richtext">
//                 {({ field, form }) => (
//                   <div className="text-editor" style={{ margin: "auto 0px" }}>
//                     <RichTextEditor name="richtext" field={field} />
//                     {form.errors.richtext && form.touched.richtext ? (
//                       <div className="explain">{form.errors.richtext}</div>
//                     ) : null}
//                   </div>
//                 )}
//               </Field>
//               <div
//                 style={{
//                   paddingBottom: 24,
//                   marginTop: 16
//                 }}
//               >
//                 <Button htmlType="submit" disabled={!isValid || isSubmitting}>
//                   Submit
//                 </Button>
//               </div>
//             </FormikForm>
//           );
//         }}
//       </Formik>
//     </div>
//   );
// }

// const rootElement = document.getElementById("root");
// ReactDOM.render(<MyPage />, rootElement);



import React from "react";
// import { Editor } from "@tinymce/tinymce-react";
import Editor from "../components/TextEditor"
import { Upload, Skeleton, Switch,  Avatar, Button, Modal, Form, Input, Divider  } from 'antd';

import {Card} from "react-bootstrap"


class MyPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { content: "" };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(content, editor) {
    this.setState({content});
  }

  handleSubmit(event) {
    alert("Text was submitted: " + this.state.content);
    event.preventDefault();
  }

  render() {
    return (
      <Card >
      <form onSubmit={this.handleSubmit}>
       <div style={{marginTop:"20px"}}>
        <Form.Item
            
              name="projectName"
              rules={[{ required: false, message: `Project Name Required` }]}
            >
              <Input placeholder="Demo Project" />
            </Form.Item>
            </div>
        {/* <Editor
          value={this.state.content}
          init={{
            height: 500,
            menubar: false
          }}
          onEditorChange={this.handleChange}
        /> */}
       
        <Editor
        style={{height:"100%"}}
         value={this.state.content}
         init={{
           height: 500,
           menubar: false
         }}
         onEditorChange={this.handleChange} />
         
        <br />
        <div style={{marginLeft:"30px", marginBottom:"40px",marginTop:"40px"}}>
        <input type="submit" value="Submit" className="btn btn-primary pull-right" />
        </div>
      </form>
      </Card>
    );
  }
}

export default MyPage;

