export const sizePerPageList = [
  { text: "10", value: 10 },
  { text: "25", value: 25 },
  { text: "50", value: 50 },
];

export const statusClass = {
  awaiting_onboard: "",
  awaiting_approval: "danger",
  approved: "success",
  rejected: "danger",
  banned: "",
  on_hold: "",
  succeeded: "success",
  awaiting_payment: "warning",
  cancelled: "danger",
  failed: "danger",
  booked: "success",
  complete: "success",
  completed: "success",
  awaiting_assignment: "warning",
  awaiting_resolution: "danger",
  processing: "success",
  isSubmited: "success",
  pending: "warning",
  resolved: "success",
  availabe: "",
  inappropriate_content: "danger",
  copyright_issue: "warning",
  spam: "warning",
  affecting_privacy: "danger",
  political_issue: "warning",
  fallacious_content: "danger",
  other: "",
  published: "success",
  on_hold: "warning",
  revoked: "danger",
};

// export const formatDateTime = (data, date_only) => {
//   const { selectedLang } = JSON.parse(localStorage.getItem("i18nConfig"));
//   if (selectedLang) {
//     if (typeof data === "string" && data.includes("T") && !date_only) {
//       return selectedLang === "jp"
//         ? moment(data)
//             .utc()
//             .local()
//             .format("YYYY年MM月DD日 HH時mm分")
//         : moment(data)
//             .utc()
//             .local()
//             .format("YYYY-MM-DD HH:mm");
//     }
//     return selectedLang === "jp"
//       ? moment(data)
//           .utc()
//           .local()
//           .format("YYYY年MM月DD")
//       : moment(data)
//           .utc()
//           .local()
//           .format("YYYY-MM-DD");
//   }
// };
// export const formatDate = (data) => {
//   return moment(data).format("YYYY-MM-DD");
// };

// export const msgDateFormat = (datetime) => {
//   if (moment(datetime).isSame(new Date(), "day")) {
//     return moment(datetime).format("HH:mm");
//   }
//   return moment(datetime).format("YYYY-MM-DD HH:mm");
// };

export const parseJwt = (token) => {
  let base64Url = token && token.split(".")[1];
  let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  let jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
};
// export const textFormatter = (text) => {
//   return text.replace("_", " ");
// };

// export const parseDateRange = (date) => {
//   return {
//     startDate: moment(date)
//       .startOf("month")
//       .format("YYYY-MM-DD"),
//     endDate: moment(date)
//       .endOf("month")
//       .format("YYYY-MM-DD"),
//   };
// };

export const bytesToSize = (bytes, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};
